const trans = {
  namespace: "merchantList",
  language: "en_us",
  translations: {
    pageTitle: "Merchant List",
    productType: "Type Of Merchant",
    code: "Merchant Code",
    name: "Display Name",
    currency: "Currency",
    remark: "Merchant Description",
    ownerName: "Chief Brand Officer",
    ownerEmail: "Email Of Chief Brand Officer",
    ownerPhone: "Phone Of Chief Brand Officer",
    assignedUser: "Assign The Users",
    cny: "CNY",
    twd: "TWD",
    vnd: "VND",
    usd: "USD",
    php: "PHP",
  },
};

export default trans;
