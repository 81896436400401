const trans = {
  namespace: "merchantList",
  language: "zh_tw",
  translations: {
    pageTitle: "商戶清單",
    productType: "商戶類型",
    code: "商戶代碼",
    name: "商戶顯示名稱",
    currency: "幣別",
    remark: "商戶描述",
    ownerName: "品牌負責人",
    ownerEmail: "品牌負責人Email",
    ownerPhone: "品牌負責人電話",
    assignedUser: "指定使用者",
    cny: "人民幣",
    twd: "台幣",
    vnd: "越南盾",
    usd: "美金",
    php: "披索",
  },
};

export default trans;
